function onDOMLoaded() {
    let fragment = document.querySelectorAll(".funding__featured-image__transition");
    if (fragment.length === 0) {
        return false;
    }

    const elmnt = fragment[0];

    
    const captionFragment = document.querySelectorAll(".funding__featured-image__transition-caption");
    const captionElmnt = captionFragment[0];


    if (fragment.length < 2) {
        elmnt.classList.remove("multiple_images-inital");
        captionElmnt.classList.remove("multiple_images");
        captionElmnt.classList.add("intitial-transition");
    } else {
        setInterval(transition, 5000)
    }


    function transition() {
        const newNode = document.querySelector(".funding__featured-image__transition");
        newNode.classList.add("multiple_images");
        newNode.classList.remove("multiple_images-inital");
        const parentDiv = document.querySelector(".funding__featured-image__transition").parentNode;
        parentDiv.insertBefore(newNode, document.getElementById("#featuredimage0"));

        
        const newNodeCaption = document.querySelector(".funding__featured-image__transition-caption");
        newNodeCaption.classList.add("multiple_images");
        newNodeCaption.classList.remove("intitial-transition");
        const parentDivCaption = document.querySelector(".funding__featured-image__transition-caption").parentNode;
        parentDivCaption.insertBefore(newNodeCaption, document.getElementById("#featuredimageCaption0"));
    }
}


export { onDOMLoaded };
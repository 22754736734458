import {onDOMLoaded as grantsTabulatorOnLoad} from './grants/GrantsTabulator';
import {onDOMLoaded as fundingAreasCarouselOnLoad} from './FundingAreasCarousel';
import {onDOMLoaded as navOnLoad} from './nav';
import {onDOMLoaded as gutterImgOnLoad} from './gutter-image';
import {onDOMLoaded as staffGridModalOnLoad} from './staff/grid-card-modal';
import {onDOMLoaded as staffModalAPIOnLoad} from './staff/api-modal';
import {onDOMLoaded as mapOnLoad} from './Map';
import {onDOMLoaded as fundingIndexOnLoad} from './accordions';
import {onDOMLoaded as newsIndexOnLoad} from './NewsList';
import {onDOMLoaded as featuredImages} from './imageFadeTransition.js';
import {onDOMLoaded as macAccordionsOnLoad} from './MacAccordion';

// There are many scripts in this repo that want to do something when
// all of the domContent has loaded, so we'll handle all of those here.
// Scripts that need this functionality are expected to export an
// onDOMLoaded function.

function attachAllDOMLoadedListeners() {
  // These add elements to the DOM, so you want to run these before the others.
  /////////

  

  // These can run anywhere, but make sure each function takes responsibility for checking that the DOM has whatever it needs to work.
  grantsTabulatorOnLoad();
  fundingAreasCarouselOnLoad();
  navOnLoad();
  staffGridModalOnLoad();
  staffModalAPIOnLoad();
  mapOnLoad();
  gutterImgOnLoad();
  fundingIndexOnLoad();
  newsIndexOnLoad();
  macAccordionsOnLoad();
  featuredImages();
}

if (document.readyState === 'loading') {  // Loading hasn't finished yet
  document.addEventListener('DOMContentLoaded', attachAllDOMLoadedListeners);
} else {  // `DOMContentLoaded` has already fired
  attachAllDOMLoadedListeners();
}

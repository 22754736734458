import * as React from 'react';
import { Disclosure, Transition } from "@headlessui/react"
import { createRoot } from "react-dom/client";

interface MacAccordionProps {
    heading: string;
    children?: React.ReactNode;
}

const MacAccordion: React.FC<MacAccordionProps> = ({ heading, children }) => {
    return (<Disclosure>
        {({ open }) => (
            <div className="first:border-t border-b border-purple-400 group">
                <Disclosure.Button className="flex w-full items-center justify-between">
                    <div className="tags-small uppercase pt-[29px] pb-[38px]">{heading}</div>
                    <div
                        className={`toggle-btn flex items-center justify-center w-11 h-11 ml-auto transform border border-gray-400 rounded-full cursor-pointer ${open ? 'open' : ''
                            }`}
                    >
                        <span className="toggle w-6 h-6 overflow-hidden transition-transform duration-300 transform"></span>
                    </div>
                </Disclosure.Button>
                {/* TODO: Why did taylor set a max height of 1500px? Bumped it to 2500 just to make sure we render on mobile. */}
                <Transition
                    show={open}
                    enter="transition-all duration-300 ease-in"
                    enterFrom="transform max-h-0 opacity-0"
                    enterTo="transform max-h-[2500px] opacity-100"
                    leave="transition-all duration-300 ease-out"
                    leaveFrom="transform max-h-[2500px] opacity-100"
                    leaveTo="transform max-h-0 opacity-0"
                    className="overflow-hidden"
                >
                    <Disclosure.Panel className="h-auto w-full pb-10">
                        <div className="flex gap-10">
                            <div className="flex-1">
                                {children}
                            </div>
                        </div>
                    </Disclosure.Panel>
                </Transition>
            </div>
        )}
    </Disclosure>)
}

function onDOMLoaded() {
    const accordions: NodeListOf<HTMLDivElement> = document.querySelectorAll('.accordion-content[data-accordion-heading]')
    for (let index = accordions.length - 1; index >= 0; index--) {
        const accordion = accordions[index];
        console.info(accordion);
        const parent = accordion.parentElement;
        const accordionHeading = accordion.dataset.accordionHeading;
        const renderRoot = document.createElement('div')
        renderRoot.classList.add('accordion')
        parent.prepend(renderRoot)
        createRoot(renderRoot).render(
            <MacAccordion heading={accordionHeading}>
                <div dangerouslySetInnerHTML={{ __html: accordion.innerHTML }} />
            </MacAccordion>)
        accordion.remove();
    }
}

export { onDOMLoaded };
import * as React from 'react';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { conditionallyRenderReact } from './utilities';

type FundingAreasProps = {
  content: {
    id: string;
    slug: string;
    title: string;
    featuredImage: Array<{
      url: string;
    }>;
  };
};

const Square = ({ item }) => {
  console.info(item)
  return (
    <div className="aspect-square h-full md:h-auto w-full group">
      <a href={`${item.url}`}>
        <div className="relative h-full w-full">
          <div className="absolute inset-0">
            <img className={'object-cover w-full h-full'} src={item.featuredImage.url} />
          </div>
          <div className="w-full h-full absolute inset-0">
            <div className="text-[28px] leading-[32px] tracking-[1px] md:heading2 text-white flex items-end md:items-start z-20 px-5 pb-[68px] md:p-6 lg:py-12 lg:px-[52px] opacity-100 md:opacity-60 md:group-hover:opacity-100 transition-all duration-150 absolute inset-0">
              {item.title}
            </div>
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black md:to-transparent md:bg-purple-400 md:bg-opacity-0 md:group-hover:bg-opacity-60 z-10 duration-150" />
          </div>
        </div>
      </a>
    </div>
  );
};

const Mobile = ({ content }) => (
  <div className="block md:hidden">
    <CarouselProvider
      naturalSlideWidth={414}
      naturalSlideHeight={500}
      totalSlides={3}
      touchEnabled={true}
      className="relative"
      infinite={true}
      isPlaying={true}
    >
      <Slider className="relative">
        {content.map((item, index) => (
          <Slide index={index} key={item.id}>
            <Square item={item} />
          </Slide>
        ))}
      </Slider>
      <div className="absolute bottom-0 flex justify-center w-full pb-8">
        <DotGroup />
      </div>
    </CarouselProvider>
  </div>
);

const FundingAreas = (props: FundingAreasProps) => {
  return (
    <section className="h-full w-full">
      <Mobile content={props.content} />
    </section>
  );
};

function onDOMLoaded() {
  const data = window.fundingAreasContent;
  conditionallyRenderReact(
    'funding-areas-carousel--js',
    <FundingAreas content={data} />,
    data
  );
}

export { onDOMLoaded };

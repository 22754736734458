import { prepareDesktopDropdowns, prepareMobileDropdowns, prepareNav, listenToSubNavLinks, highlightActivePage } from "./primaryNav";
import { prepareUpperNav } from "./secondaryNav";
import { searchBarClickListener } from "./searchBarFocus";

function onDOMLoaded() {
	prepareDesktopDropdowns();
	prepareMobileDropdowns();
	listenToSubNavLinks();
	prepareNav();
	prepareUpperNav();
	searchBarClickListener();
	highlightActivePage();
}

export {onDOMLoaded};
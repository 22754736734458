import { gsap } from "gsap";

let lastRanAt = 0;
const TIMELINE_DURATION = 1000;
const DESKTOP_BREAKPOINT = 1080;

// IDEA FOR A SMOOTHER ANIMATION: Use request animation frame and, when the intersection takes place, instantiate a
// canvas that you start drawing and scaling to. This just sits on top of everything, completely separate from the
// layout algorithm

// Alternatively, we could get a sense of the scroll velocity & give up on trying to smoothly tween when they're
// moving too fast -- just drop it in from the top with a fade in

function prepareGutterAnimation() {
	const heroImg = document.getElementById('hero-image-js') as HTMLImageElement;
	if (!heroImg) {
		return false;
	}
	const gutterImg = document.getElementById('gutter-image-js');
	const flowContentContainer = document.querySelector('.news-entry.layout-grid');
	const navHeightProxy = document.getElementById('observe-nav-scroll-js');
	const shareCard = document.getElementById('share-card-js');

	if ([heroImg, gutterImg, navHeightProxy, shareCard].some(x => x === null)) {
		throw "Missing one of the required dom elements for gutter image animation."
	}

	const bannerGrid = heroImg.closest('.banner-grid--with-img');

	if (bannerGrid === null) {
		throw "Missing the .banner-grid--with-img selector"
	}

	function buildTimeline() {
		const timeline = gsap.timeline({paused: true})
		const scale = 0.4 // going from 10 columns to 4 columns;
		const naturalHeight = heroImg.clientHeight;
		const bannerBottomMargin = flowContentContainer.getBoundingClientRect().top - bannerGrid.getBoundingClientRect().bottom;
		const yOffset = naturalHeight * scale + bannerBottomMargin;
		timeline.set(heroImg, {opacity: 1})
			.set(gutterImg, {opacity: 0})
			.to(heroImg, {scale: scale, y: yOffset})
			.set(gutterImg, {opacity: 1})
			.set(heroImg, {opacity: 0})
			.fromTo(shareCard, {opacity: 0, y: 0}, {opacity: 1, marginTop: 38})
		return timeline;
	}

	const timeline = buildTimeline();

	const callback = (entries, observer) => {
		entries.forEach((entry) => {
			if (window.innerWidth >= DESKTOP_BREAKPOINT && (lastRanAt + TIMELINE_DURATION) <= entry.time) {
					lastRanAt = entry.time
					if (entry.isIntersecting) {
						timeline.reverse()
					} else {
						timeline.play()
					}
				}
		});
	};

	const options = {
    threshold: 0.6,
    rootMargin: `-${navHeightProxy.clientHeight}px 0px 0px 0px`,
  };

	const observer = new IntersectionObserver(callback, options);
	observer.observe(bannerGrid)

}

function onDOMLoaded() {
	if (document.body.classList.contains('news-entry')) {
		prepareGutterAnimation()
	}
}

export {onDOMLoaded};
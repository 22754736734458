import { conditionallyRenderReact } from './utilities';
import * as React from 'react';
import { Disclosure, Transition } from '@headlessui/react';

type AccordionProps = {
  content: Array<{
    id: number;
    heading: string;
    copy: string;
    callout?: string;
    children?: React.ReactNode;
  }>;
};

const Accordions = (props: AccordionProps) => {
  return (
    <>
      <div className="accordion mt-[56px]">
        {props.content.map((item) => (
          <Disclosure key={item.id}>
            {({ open }) => (
              <div className="first:border-t border-b border-purple-400 group">
                <Disclosure.Button className="flex w-full items-center justify-between">
                  <div className="tags-small uppercase pt-[29px] pb-[38px]">{item.heading}</div>
                  <div
                    className={`toggle-btn flex items-center justify-center w-11 h-11 ml-auto transform border border-gray-400 rounded-full cursor-pointer ${
                      open ? 'open' : ''
                    }`}
                  >
                    <span className="toggle w-6 h-6 overflow-hidden transition-transform duration-300 transform"></span>
                  </div>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition-all duration-300 ease-in"
                  enterFrom="transform max-h-0 opacity-0"
                  enterTo="transform max-h-[1500px] opacity-100"
                  leave="transition-all duration-300 ease-out"
                  leaveFrom="transform max-h-[1500px] opacity-100"
                  leaveTo="transform max-h-0 opacity-0"
                  className="overflow-hidden"
                >
                  <Disclosure.Panel className="h-auto w-full pb-10">
                    <div className="flex flex-col md:flex-row gap-10 md:group-even:flex-row-reverse">
                      <div className="flex-1">
                        <div
                          className={'wysiwyg'}
                          dangerouslySetInnerHTML={{ __html: item.copy }}
                        />
                      </div>
                      {item.callout && (
                        <div className="caption bg-purple-400 text-white max-w-[288px] p-6 h-fit" dangerouslySetInnerHTML={{ __html: item.callout }}/>
                      )}
                    </div>
                  </Disclosure.Panel>
                </Transition>
              </div>
            )}
          </Disclosure>
        ))}
      </div>
    </>
  );
};

function onDOMLoaded() {
  const data = window.fundingComposer?.data?.entry?.fundingComposer[0]?.accordion;
  conditionallyRenderReact('accordions-root-js', <Accordions content={data} />, data);
}

export { onDOMLoaded };

export class Crasher {
	constructor(node, toolbar) {
		this.toolbar = toolbar;
		this.contentSelector = '.slide-in-content';
		this.node = node;
		this.label = node.querySelector('.label');
		this.content = node.querySelector(this.contentSelector);
		this.closeButton = node.querySelector(`button[aria-controls='${node.id}']`)
		this.timeline = null;
		this.animatedChildren = [this.label, this.content];
		if ([this.label, this.content, this.closeButton].some(x => x === null)) {
			throw `Missing a dom node in the ${node}`;
		}
	}

	load(event) {
		const triggerEl = event.target;
		const detailsEl = event.currentTarget;
		const summaryEl = detailsEl.querySelector('summary');

		const newContent = detailsEl.querySelector(this.contentSelector);
		if (newContent) {
			const newClone = newContent.cloneNode(true);
			this.node.replaceChild(newClone, this.content);
			this.content = newClone;
			if (summaryEl.dataset.skipLabel === 'true') {
				this.label.innerText = '';
				this.animatedChildren = [this.content];
			} else {
				this.label.innerText = summaryEl.innerText + ':';
				this.animatedChildren = [this.label, this.content];
			}

		} else {
			throw `Missing a node with the ${this.contentSelector} class`;
		}
	}

	animate(timeline) {
		this.node.classList.add('interactive');

		const rightwardDisplacement = window.innerWidth - this.toolbar.getBoundingClientRect().left;
		timeline.set(this.node, { opacity: 1 });
		timeline.set([this.node, this.label, this.content, this.closeButton], { x: rightwardDisplacement });
		timeline.to(this.node, { x: 0 });
		timeline.to(this.animatedChildren, { x: 0, stagger: { each: 0.25 } });
		timeline.to(this.closeButton, { x: 0 });
		timeline.timeScale(1.5);

		this.timeline = timeline;
	}

	animateOut() {
		this.node.classList.remove('interactive');
		this.timeline.reverse();
	}
}

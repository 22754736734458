import { Fragment } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import SelectorIcon from '../../../web/assets/images/icons/selector.svg';
import { Icon, RemoveFilterIcon } from '../Icon';
import ReactSlider from 'react-slider';
import * as React from 'react';
import { NumericCategory, YourFormElement } from '../types';

function SpecialNote(props: { wysiwyg: string }) {
  return (
    <div className={`grants-special-note`} dangerouslySetInnerHTML={{ __html: props.wysiwyg }} />
  )
}

interface RangeFunc {
  (range: [number, number]): void;
}

interface StringFunc {
  (string: string): void;
}

interface NumberFunc {
  (number: number): void;
}

interface GrantFormProps {
  selectedCategories: NumericCategory[];
  searchValue: string;
  sliderYearRange: number[];
  sliderAmountRange: number[];
  activeSortType: string;
  activeSortOrder: string;
  categories: NumericCategory[];
  rangeLimits: { year: { min: number; max: number }; amount: { min: number; max: number } };
  setSelectedCategories: React.Dispatch<React.SetStateAction<Array<NumericCategory>>>;
  setSearchValue: StringFunc;
  setSliderYearRange: RangeFunc;
  setSliderAmountRange: RangeFunc;
  setActiveSortType: StringFunc;
  setActiveSortOrder: StringFunc;
  copyQueryString: () => void;
  handleSubmit: (event: React.FormEvent<YourFormElement>) => void;
}

const GrantsForm = ({
  selectedCategories,
  setSelectedCategories,
  searchValue,
  setSearchValue,
  sliderYearRange,
  setSliderYearRange,
  sliderAmountRange,
  setSliderAmountRange,
  activeSortType,
  setActiveSortType,
  activeSortOrder,
  setActiveSortOrder,
  categories,
  rangeLimits,
  copyQueryString,
  handleSubmit
}: GrantFormProps) => {
  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3,
  });

  const sortStyles = [
    { value: 'paymentDate', label: 'Date' },
    { value: 'amount', label: 'Amount' },
    {
      value: 'granteeName',
      label: 'A-Z',
    },
  ];

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handeSortChange = (e) => {
    // If a button is selected, toggle order. otherwise select it and set an order
    if (activeSortType === e.currentTarget.value) {
      if (activeSortOrder === 'DESC') {
        setActiveSortOrder('ASC');
      } else {
        setActiveSortType('');
        setActiveSortOrder('');
      }
    } else {
      setActiveSortType(e.currentTarget.value);
      setActiveSortOrder('DESC');
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="font-sans text-purple-400 mb-2">Filter Grants</div>
        <Combobox multiple={true} value={selectedCategories} by={(a, b) => { return a.id === b.id }} onChange={(value) => setSelectedCategories(value)}>
          <div className="relative font-sans">
            <Combobox.Button className="relative w-full cursor-default text-white border border-purple-400 bg-purple-400 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
              <span className="block truncate">{selectedCategories.length == 1 ? selectedCategories[0].shortForm || selectedCategories[0].title : `Categories`}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 fill-white">
                <SelectorIcon />
              </span>
            </Combobox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[5] space-y-1">
                <li onClick={() => setSelectedCategories([])} className={`hover:bg-gray-200 relative cursor-default select-none py-2 pl-3 first:-mt-1 ${selectedCategories.length == 0 ? 'bg-gray-200' : 'bg-gray-100'} `}>
                  <div className={`flex justify-between items-center`}>
                    <div className="truncate text-purple-400 font-bold">All Categories</div>
                    {selectedCategories.length == 0 && <div className="shrink-0 mr-2"><RemoveFilterIcon /></div>}
                  </div>
                </li>
                {categories.map(category => (
                  <Combobox.Option
                    key={category.id}
                    className={`hover:bg-gray-200 relative cursor-default select-none py-2 pl-3 first:-mt-1 ${selectedCategories.map(x => x.id).includes(category.id) ? 'bg-gray-200' : 'bg-gray-100'}`}
                    value={category}
                  >
                    {({ selected }) => (
                      <div className={`flex justify-between items-center`}>
                        <div className="truncate text-purple-400 font-bold">{category.shortForm || category.title}</div>
                        {selected && <div className="shrink-0 mr-2"><RemoveFilterIcon /></div>}
                      </div>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>

        <div className="slider-section mt-4 mb-6">
          <div className="range-descriptor text-center mb-6">
            <div className="block caption">
              <span className="year-min">{sliderYearRange[0]}</span> to{' '}
              <span className="year-max">{sliderYearRange[1]}</span>
            </div>
            <ReactSlider
              className="grantSlider"
              trackClassName="grantSlider-track"
              thumbClassName="grantSlider-thumb"
              pearling
              defaultValue={sliderYearRange}
              value={sliderYearRange}
              onChange={(value) => setSliderYearRange(value)}
              min={rangeLimits.year.min}
              max={rangeLimits.year.max}
            />
          </div>

          <div className="range-descriptor text-center mb-10">
            <div className="block caption">
              <span className="amount-min">{dollarUS.format(sliderAmountRange[0])}</span> to{' '}
              <span className="amount-max">{dollarUS.format(sliderAmountRange[1])}</span>
            </div>
            <ReactSlider
              className="grantSlider"
              trackClassName="grantSlider-track"
              thumbClassName="grantSlider-thumb"
              pearling
              defaultValue={sliderAmountRange}
              value={sliderAmountRange}
              onChange={(value) => setSliderAmountRange(value)}
              min={rangeLimits.amount.min}
              max={rangeLimits.amount.max}
            />
          </div>
        </div>

        <div className="flex justify-between items-center gap-3 mb-4">
          {sortStyles.map(({ label, value }) => (
            <button
              key={label}
              type="button"
              className={`flex border border-gray-300 flex-1 justify-between p-2 items-center tags-small-light ${activeSortType === value ? 'bg-purple-400 text-white' : 'bg-white'
                }`}
              value={value}
              onClick={handeSortChange}
            >
              <div>{label}</div>
              <div className="h-full flex flex-col gap-[3px]">
                <div
                  className={`${activeSortType === value && activeSortOrder === 'ASC'
                      ? 'fill-white'
                      : activeSortType === value && activeSortOrder === 'DESC'
                        ? 'fill-white opacity-30'
                        : 'fill-purple-400 opacity-100'
                    } rotate-180`}
                >
                  <SelectorIcon />
                </div>
                <div
                  className={`${activeSortType === value && activeSortOrder === 'DESC'
                      ? 'fill-white'
                      : activeSortType === value && activeSortOrder === 'ASC'
                        ? 'fill-white opacity-30'
                        : 'fill-purple-400 opacity-100'
                    }`}
                >
                  <SelectorIcon />
                </div>
              </div>
            </button>
          ))}
        </div>

        <div className="flex gap-4">
          <div className="bg-white border border-gray-300 flex items-center justify-between p-2 flex-1 tags-small-light">
            <input
              type="search"
              placeholder="Search Grants..."
              value={searchValue}
              onChange={handleSearchChange}
            />
            <Icon name="grant-search" />
          </div>
          <button onClick={() => copyQueryString()} type="button" className="border border-gray-300 bg-white px-2">
            <Icon name="grant-copy" />
          </button>
        </div>
      </form>

      <div className="mt-10 wysiwyg">
        {selectedCategories.length == 0 && categories.filter(x => x.note !== null).map(x => <SpecialNote wysiwyg={x.note} />)}
        {selectedCategories.length > 0 && selectedCategories.filter(x => x.note !== null).map(x => <SpecialNote wysiwyg={x.note} />)}
      </div>
    </>
  );
};

export { GrantsForm };

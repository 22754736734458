import { conditionallyRenderReact } from './utilities';
import * as React from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { ReactSVG } from 'react-svg';
import { RemoveFilterIcon } from './Icon';
import { Category } from './types';

function SelectorIcon() {
  return (
    <>
      <ReactSVG src={`/assets/images/icons/selector.svg`} />
    </>
  );
}

function Arrow() {
  return (
    <svg width="18" height="8" viewBox="0 0 18 8" fill="" xmlns="http://www.w3.org/2000/svg" className={'ml-2 inline fill-purple-400 opacity-0 group-hover:opacity-100 transition duration-500 peer-hover:translate-x-1'}>
      <path d="M14.6104 3.38965C14.0537 2.72559 13.4727 1.76367 12.8672 0.503906H13.9219C15.1621 1.96875 16.4805 3.05273 17.877 3.75586V4.2832C16.4805 4.98633 15.1621 6.07031 13.9219 7.53516H12.8672C13.4727 6.27539 14.0537 5.31348 14.6104 4.64941H0.123047V3.38965H14.6104Z" />
    </svg>
  );
}

function LazyImage(props: { alt: string, className: string, src: string }) {
  const imgRef = useRef(null);
  const [show, setShowing] = useState(false);

  useEffect(() => {
    if (imgRef.current) {
      const node = imgRef.current as HTMLImageElement;
      if (node.complete) {
        setShowing(true)
      } else {
        node.addEventListener('load', () => setShowing(true))
      }
      return node.removeEventListener('load', () => setShowing(true))
    }
  }, [imgRef])
  return (
    <img
      ref={imgRef}
      alt={props.alt}
      style={{ opacity: show === true ? 1 : 0 }}
      className={`${props.className} duration-500 transition-opacity`}
      src={props.src}
    />
  )
}

function RowCard(props: { item: Article }) {
  const { item } = props;
  const category = item.category[0].shortForm || item.category[0].title;
  return (
    <Transition
      appear={true}
      show={true}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="layout-grid news-article-card group">
        <hr></hr>
        <a href={item.url} className="click-catcher"></a>
        <h4 className="hover:text-purple-300 news-article-card__category tags-small text-purple-400"><a href={`/news?category[]=${item.category[0].id}`}>{category}</a></h4>
        <LazyImage
          alt={item.featuredImage[0].altText}
          className={``}
          src={`${item.featuredImage[0].url}?w=328&h=184&fit=crop&crop=faces,entropy`}
        />
        <div className="news-article-card__title">
          <h3 className="h3 text-gray-500 group-hover:text-purple-400" dangerouslySetInnerHTML={{ __html: item.title }}></h3>

          <div className="tags-small-light text-gray-400 flex items-center gap-3 mt-4 mb-[-0.25em]">
            <a href={item.url} className="text-gray-400 hover:text-purple-400">
              <span className="peer">Read Article</span> <Arrow />
            </a>
          </div>
        </div>
      </div>
    </Transition>
  );
}

function compareCategories(a: Category, b: Category) {
  return a.id === b.id
}

function filterArticles(articles: Article[], selectedCategories: Array<Category>, selectedDate: number): Article[] {
  let correctCategory = true;
  let correctPostDate = true;
  return articles.filter(
    (article) => {
      if (selectedCategories.length > 0) {
        const articleCategory = article?.category[0]
        correctCategory = selectedCategories.some(cat => compareCategories(cat, articleCategory))
      }

      correctPostDate = selectedDate >= new Date(article.postDate).getFullYear()

      return correctCategory && correctPostDate;
    }
  )
}

interface Article {
  id: number;
  title: string;
  url: string;
  featuredImage: Array<{
    url: string;
    altText: string;
  }>;
  postDate: string;
  category: Array<Category>;
  slug: string;
}

type NewsProps = {
  data: { articles: Article[], categories: Array<Category> };
  filter?: boolean;
};

const NewsList = (props: NewsProps) => {
  const { articles, categories } = props.data;

  const filterDates = Array.from(
    new Set(articles.map((obj) => new Date(obj.postDate).getFullYear()))
  );

  const initialState = {
    categories: [],
    visibleArticleCount: 8,
    startDate: filterDates[0]
  };

  if (window.location.search.length > 0) {
    const params = new URLSearchParams(window.location.search);

    if (params.get('category[]')) {
      const categoryIds = params.getAll('category[]')
      initialState['categories'] = categories.filter(category => categoryIds.includes(category.id));
    }
  }

  const [visibleArticleCount, setVisibleArticleCount] = useState(initialState.visibleArticleCount);
  const [selectedCategories, setSelectedCategories] = useState(initialState.categories);
  const [selectedDate, setSelectedDate] = useState(initialState.startDate);

  const showMoreItems = () => {
    setVisibleArticleCount((prevValue) => prevValue + 8);
  };

  const filteredArticles = filterArticles(articles, selectedCategories, selectedDate)

  return (
    <section>
      <div className="container">
        <div className="w-full mb-10 lg:mb-[33px] md:max-w-md">
          <h3 className="font-sans text-purple-400 mb-4">Filters</h3>

          <div className="flex gap-5">
            <div className="flex-1 md:max-w-[328px]">
              <Combobox multiple={true} value={selectedCategories} by={compareCategories} onChange={setSelectedCategories}>
                <div className="relative font-sans">
                  <Combobox.Button className="relative w-full cursor-default text-white border border-purple-400 bg-purple-400 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 h-full">
                    <span className="block truncate font-bold">{
                      selectedCategories.length == 1 ? selectedCategories[0].shortForm || selectedCategories[0].title : `Categories`
                    }</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 fill-white">
                      <SelectorIcon />
                    </span>
                  </Combobox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Combobox.Options className="absolute mt-1 max-h-60 w-full z-50 overflow-auto bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[5] space-y-1 overscroll-contain">
                      <li onClick={() => setSelectedCategories([])} className={`hover:bg-gray-200 relative cursor-default select-none py-2 pl-3 first:-mt-1 ${selectedCategories.length == 0 ? 'bg-gray-200' : 'bg-gray-100'} `}>
                        <div className={`flex justify-between items-center`}>
                          <div className="truncate text-purple-400 font-bold">All Categories</div>
                          {selectedCategories.length == 0 && <div className="shrink-0 mr-2"><RemoveFilterIcon /></div>}
                        </div>
                      </li>
                      {categories.map((category) => (
                        <Combobox.Option
                          key={category.id}
                          className={`relative hover:bg-gray-200 cursor-default select-none py-2 pl-3 first:-mt-1 ${selectedCategories.includes(category) ? 'bg-gray-200' : 'bg-gray-100'}`}
                          value={category}
                        >
                          {({ selected }) => (
                            <div className={`flex justify-between items-center`}>
                              <div className="truncate text-purple-400 font-bold">{category.shortForm || category.title}</div>
                              {selected && <div className="shrink-0 mr-2"><RemoveFilterIcon /></div>}
                            </div>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
            </div>
            <Combobox value={selectedDate} onChange={setSelectedDate}>
              <div className="relative font-sans">
                <Combobox.Button className="relative w-full cursor-default text-gray-500 border border-gray-500 bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 h-full">
                  <span className="block truncate tag-small--light">{selectedDate}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 fill-gray-500">
                    <SelectorIcon />
                  </span>
                </Combobox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[5] space-y-1">
                    {filterDates.map((filter, index) => (
                      <Combobox.Option
                        key={index}
                        className={({ active }) =>
                          `hover:bg-gray-200 relative cursor-default select-none py-2 px-3 first:-mt-1 bg-gray-100 ${active ? '' : ''
                          }`
                        }
                        value={filter}
                      >
                        {(selectedDate) => (
                          <>
                            <span
                              className={`block truncate text-purple-400 font-bold ${selectedDate ? '' : ''
                                }`}
                            >
                              {filter}
                            </span>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </Transition>
              </div>
            </Combobox>
          </div>
        </div>
      </div>

      <Transition
        show={filteredArticles.length == 0}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="mt-12 h5-serif text-gray-400 container">No articles found, please change your search terms.</div>
      </Transition>
      <Transition
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={filteredArticles.length > 0}
      >
        {filteredArticles.slice(0, visibleArticleCount).map((item) => (
          <RowCard key={item.title} item={item} />
        ))}
        <div className="constrained-rule border-t pt-[80px] mb-[110px] flex justify-center">
          <a onClick={showMoreItems}
            className="bg-purple-400 hover:bg-purple-300 px-6 py-5 leading-[19px] tracking-[0.01em] text-xxs font-sans text-white flex items-center hover:no-underline w-max cursor-pointer">
            <span>View More News</span>
          </a>
        </div>
      </Transition>
    </section>
  );
};

function onDOMLoaded() {
  const data = { articles: window.newsEntries?.data?.entries, categories: window.newsCategories?.data?.categories };
  conditionallyRenderReact('news-index-root-js',
    <NewsList data={data} />,
    Array(Object.values(data)).every(x => x !== null))
}

export { onDOMLoaded };

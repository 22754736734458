import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import * as ReactDOM from "react-dom/client";
import * as React from "react";

const Map = () => {
    // Loads the map using API KEY
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyB5Av2quPyPVrAGatzY7k7x6ogqpeY4Sn4",
    });
    // This returns while map is being loaded
    const myLatLng = { lat: 41.8943826, lng: -87.6262779 };

    if (loadError) {
      return <div>Map cannot be loaded right now, sorry.</div>
    }
    if (!isLoaded) return <div>Loading...</div>
    console.log("hello");
    
    return (
        <GoogleMap
            mapContainerClassName={`w-full h-full ${(isLoaded ? '' : 'bg-gray-400')}`}
            center={myLatLng}
            zoom={13}
            options={{
                scrollwheel: false,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
            }}
        >
            <Marker
                position={myLatLng}
            />
        </GoogleMap>
    )
}

function onDOMLoaded() {
  const contactMapRoot = document.getElementById('google-map-contact-page');
  if (contactMapRoot) {
    ReactDOM.createRoot(contactMapRoot).render(
        <Map/>
    );
  }
}

export { Map, onDOMLoaded };
function onDOMLoaded() {
	const staffCards = document.querySelectorAll('.staff-directory-grid.contact-info');
	const closeButton = document.getElementById('close-staff-modal-js');
	const dialog = document.getElementById('staff-bio-modal-js');

	if (staffCards.length > 0 && closeButton && dialog) {
		const dialogLayout = dialog.querySelector('section');
		// if the target of the click is the email address, stop propagation
		if (dialogLayout) {
			for (const staffCard of staffCards) {
				const stafferId = staffCard.dataset.stafferId;
				staffCard.querySelector('.staff-card__email').addEventListener('click', (event) => {
					event.stopPropagation();
				})
				staffCard.addEventListener('click', (event) => {
					fetch(`/staff/bio`, {
						headers: {"Content-Type": "application/json"},
						method: 'POST',
						body: JSON.stringify({id: stafferId}),
						mode: 'same-origin'
					})
					.then( (response) => {
						if (!response.ok) {
							throw new Error(`HTTP error! Status: ${response.status}`);
						}

						return response.text();
					})
					.then( (response) => {
						dialogLayout.innerHTML = response;
						dialogLayout.prepend(closeButton);
						dialog.showModal();
					})
					.catch((error) => console.error(error))
				});
			}

			// Form close button closes the dialog box
			closeButton.addEventListener('click', () => {
				dialog.close();
			});
		}
	}
}

export { onDOMLoaded };
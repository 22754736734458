function onDOMLoaded() {
  const staffCards = document.querySelectorAll('.staff-grid__card');
  const closeButton = document.getElementById('close-staff-modal-js');
  const dialog = document.getElementById('staff-bio-modal-js') as HTMLDialogElement | null;

  if (staffCards.length > 0 && closeButton && dialog) {
    const dialogLayout = dialog.querySelector('section');
    if (dialogLayout) {
      for (const staffCard of staffCards) {
        const clonedChildren = [...staffCard.children].map(x => x.cloneNode(true))
        staffCard.addEventListener('click', () => {
          dialogLayout.replaceChildren(closeButton, ...clonedChildren);
          dialog.showModal();
        });
      }

      // Form close button closes the dialog box
      closeButton.addEventListener('click', () => {
        dialog.close();
      });
    }
  }
}

export { onDOMLoaded };

import { Dropdown } from "./Dropdown";

export const TIMEOUT_PERIOD = 300;
export const NAV_FULL_HEIGHT = 135;

export function prepareDesktopDropdowns() {
	const dropdowns = [...document.querySelectorAll('.nav-dropdown-header')].map(x => new Dropdown(x))
	for (const d of dropdowns) {
		d.otherDropdowns = dropdowns.filter(x => x.node !== d.node);
	}
}

export function prepareMobileDropdowns() {
	const dropdowns = [...document.querySelectorAll('.mobile-nav-open-dropdown-js')].forEach(x => {
		x.addEventListener('click', (event) => {
			const parent = x.closest('.top-level');
			if (event.target.tagName === 'A') {
				// If you clicked a header which is itself a page link, there are two options:
				if (parent.classList.contains('open')) {
					// The dropdown is currently open, at which point we can treat it like a
					// regular link, ie: return true early and don't execute the accordion
					return true;
				} else {
					// Or treat it as the opener for the accordion, and block the page
					// transition from occurring.
					event.preventDefault();
				}
			}
			const button = x.tagName === 'button' ? x : x.querySelector('button')
			parent.classList.toggle('open');
			if (parent.classList.contains('open')) {
				button.innerText = "−";
			} else {
				button.innerText = "+";
			}
		})
	})
}

export function listenToSubNavLinks() {
	const nav = document.getElementById('primary-nav-js');

	const dropdowns = [...document.querySelectorAll('.subnav-link-js')].forEach(x => {
		x.addEventListener('click', (event) => {

			const uri = window.location.pathname;
			const href = x.getAttribute('href');
			const url = href.startsWith('/') ? new URL(window.location.origin + href) : new URL(href);
			if (uri.startsWith('/about') && url.pathname.startsWith('/about')) {
				event.stopPropagation();
				nav.classList.remove('open');
				if (url.hash === '') {
					event.preventDefault();
				}
			}
		})
	})
}

export function highlightActivePage() {
	const navDropdowns = document.getElementById('nav-dropdowns');
	if (!navDropdowns) {console.error("Missing nav-dropdowns id in navbar")}
	document.querySelectorAll('#nav-dropdowns a.nav-primary').forEach(x => {
		const linkHref = x.getAttribute('href');
		if (linkHref && typeof URL !== 'undefined') {
			let linkUrl;
			try {
				linkUrl = new URL(linkHref).pathname.split('/')[1]
			} catch (error) {
				if (error.name !== 'TypeError') {
					console.error(error);
				}
				if (linkHref.startsWith('/')) {
					linkUrl = linkHref.split('/')[1];
				} else {
					linkUrl = linkHref;
				}
			}
			const currentLocation = window.location.pathname.split('/')[1];
			if (linkUrl === currentLocation) {
				x.classList.add("text-purple-300");
			}
		}
	})
}

export function prepareNav() {

	const nav = document.getElementById('primary-nav-js');
	const hamburger = document.getElementById('nav-expander-js');
	const mobileMenu = document.getElementById('mobile-menu');
	let navShrinkTimeout = undefined;

	function resetNav() {
		nav.classList.remove('minimized');
	}

	function shrinkNav() {
		nav.classList.add('minimized');
	}

	nav.addEventListener('mouseenter', function (event) {
		if (typeof navShrinkTimeout === 'number') {
			clearTimeout(navShrinkTimeout);
		}
		resetNav();
	});

	nav.addEventListener('mouseleave', function (event) {
		if (window.scrollY > NAV_FULL_HEIGHT) {
			navShrinkTimeout = setTimeout(shrinkNav, TIMEOUT_PERIOD);
		}
	});

	hamburger.addEventListener('click', function (event) {
		nav.classList.toggle('open');
	});

	let callback = (entries, observer) => {
		entries.forEach((entry) => {
			if (window.innerWidth > 768) {
				if (entry.isIntersecting) {
					nav.classList.remove('scrolled');
					resetNav();
				} else {
					shrinkNav();
					nav.classList.add('scrolled');
				}
			}
		});
	};

	let options = {
		threshold: 0
	};

	const target = document.getElementById('observe-nav-scroll-js');

	let observer = new IntersectionObserver(callback, options);

	observer.observe(target);

}

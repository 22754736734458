import { gsap } from "gsap";
import { Crasher } from "./Crasher";

export function prepareUpperNav() {

	function staggerOutButtons(timeline, items) {
		timeline.to(items, {
			opacity: 0,
			stagger: {
				each: 0.1,
				from: "end"
			}
		});
	}

	const toolbar = document.getElementById('nav-toolbar');
	const toolbarContent = toolbar.querySelector('.toolbar-content');
	const playHeadSelector = '#nav-toolbar details';
	const crasherSelector = 'crash-in-js'
	const crasherNode = document.getElementById(`${crasherSelector}`);
	const playheads = document.querySelectorAll(playHeadSelector);
	const crasherCloser = document.querySelector(`button[aria-controls="${crasherSelector}"]`);
	const crasher = new Crasher(crasherNode, toolbar);

	[toolbar, toolbarContent, crasherNode, playheads, crasherCloser].forEach((x, i) => {
		if (x === null) {
			throw `Missing dom element at index ${i} of this array`;
		}
	});

	crasherCloser.addEventListener('click', () => { crasher.animateOut(); });

	for (const playhead of playheads) {
		playhead.addEventListener("click", (event) => {
			// Stagger out the toolbars top level items, which are the labels you can click to
			// trigger the animation or jump to another page. Sweeps in from the right.
			const timeline = gsap.timeline();
			staggerOutButtons(timeline, toolbarContent.children);

			// Build the "crash cart": a div with two children.
			crasher.load(event);
			crasher.animate(timeline);
		});
	}
}

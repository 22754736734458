import { TIMEOUT_PERIOD } from "./primaryNav";

// TODO: Close mobile nav when sizing above 768
// Store some of the nav prep into this function, to not pollute scope
export class Dropdown {
	// when mouse enters, set active class and remove active from all other headers
	// when mouse exits, set a timer to close it, to give a grace period for inaccurate mousing
	constructor(node) {
		this.node = node;
		this.node.addEventListener('mouseenter', this);
		this.node.addEventListener('mouseleave', this);

		// shouldClose is either undefined or a timeoutId
		this.shouldClose = undefined;
		this.otherDropdowns = null;
		this.timeoutPeriod = TIMEOUT_PERIOD; // in ms
	}

	log(data) {
		console.info(`${this.node.getAttribute('id')} (${this.node.classList}): ${data}`);
	}

	handleEvent(event) {
		if (event.type === 'mouseenter') {
			this.initiateOpen();
		}

		if (event.type === 'mouseleave') {
			this.initiateClose();
		}
	}

	initiateOpen(event) {
		this.otherDropdowns.forEach(x => x.close());
		this.open();
	}

	initiateClose() {
		this.shouldClose = setTimeout(() => { this.close(); }, this.timeoutPeriod);
	}

	open() {
		if (typeof this.shouldClose === 'number') {
			clearTimeout(this.shouldClose);
		}
		this.node.classList.add('active');
	}

	close() {
		this.node.classList.remove('active');
	}

	hasSubNav() {
		return this.node.querySelector('.nav-dropdown-tab');
	}
}

import { ReactSVG } from "react-svg";
import * as React from "react";

type IconProps = {
    name: string;
    size?: number;
    styles?: string
}

const Icon = ( props: IconProps ) => {
    return (
        <>
            <ReactSVG src={`/assets/images/icons/${props.name}.svg`} className={`${props.styles} transition-all duration-100 h-${props.size} w-${props.size}` } />
        </>
    );
}

function RemoveFilterIcon() {
    return (<ReactSVG src={`/assets/images/icons/remove-filter.svg`} className={`${'text-gray-400'} transition-all duration-100 h-[10px] w-[9px]`} />)
  }

export { Icon, RemoveFilterIcon }